<template>
  <div class="configuration-wrapper">
    <Panel>
      <template #header>
        <div class="panel-header-content">
          <h4>{{ t('templateBuilder.panels.translation') }}</h4>
        </div>
      </template>

      <template #icons>
        <i
          v-if="isClosable"
          class="far fa-times icons-header-panel"
          @click="handleHideToolbar"
        />
      </template>

      <TabMenu
        v-model:activeIndex="activeIndex"
        :model="languages"
        class="translation-panel--menu"
      >
        <template #item="{ item }">
          <a
            class="p-menuitem-link p-highlight"
            role="presentation"
            @click="handleChangeLanguage(item.label)"
          >
            <span class="p-menuitem-text">
              <img
                :src="`/images/flags/${item.label}.png`"
                alt="language-flag"
              >
            </span>
          </a>
        </template>
      </TabMenu>
      <div>
        <div class="field-group pt-3">
          <div>
            <div class="field-group-content">
              <div
                v-for="group in groupOrder"
                :key="group"
                :ref="el => setGroupComponentRef(el, group)"
                :class="{
                  'border-left-3': groupedTranslations[group].active && groupedTranslations[group].translations.length > 1,
                  'active-group': groupedTranslations[group].active && groupedTranslations[group].translations.length > 1
                }"
                class="px-1"
              >
                <div
                  v-for="translation in groupedTranslations[group].translations"
                  :key="`${translation.key}-${translation.language}`"
                  class="field-wrapper"
                >
                  <component
                    :is="getFieldFromType(translation.fieldType, translation.key)"
                    v-if="!translation.hidden"
                    :key="componentKey"
                    :ref="el => setComponentRef(el, translation.key)"
                    v-model="translation.value"
                    :group-key="translation.groupKey"
                    :translation="translation"
                    v-bind="getPropsFromType(translation.fieldType)"
                    @update:model-value="(newValue) => handleUpdateModelValue(translation, newValue)"
                    @click="highlightWidgetOnTranslationClicked(translation)"
                    @input="translation.value = $event.target.value"
                    @activate-group="handleActivateGroup"
                    @deactivate-group="handleDeactivateGroup"
                  />
                  <SpmButton
                    v-if="activeLanguage !== defaultLanguage && isTranslatableField(translation.fieldType) && !translation.hidden"
                    icon="far fa-language"
                    :label="t('templateBuilder.translate')"
                    class="translate-button p-button-sm p-button-secondary block mx-auto my-2"
                    @click="translateValue(translation);"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Panel>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent, onBeforeMount, onBeforeUnmount,
  Ref,
  ref,
  watch,
} from 'vue';
import TabMenu from 'primevue/tabmenu';
import {
  addTranslationsToState,
  getTranslationByKeyAndLang,
  hideLeftToolbar,
  replaceTranslationInState,
  TemplateEditorState as state,
  refreshAttributeInDOM,
  setSelectedTranslationId,
  setShowConfigurationPanelCallback, restoreSmartListsOriginalCode,
  getWidgetFromTranslationKey,
  markTemplateAsEdited,
} from '@/composables/template-editor/TemplateEditor';
import BaseInputText from '@/components/fields/BaseInputText.vue';
import BaseWysiwygEditor from '@/components/fields/BaseWysiwygEditor.vue';
import BaseImage from '@/components/fields/BaseImage.vue';
import BaseTextarea from '@/components/fields/BaseTextarea.vue';
import BaseLink from '@/components/fields/BaseLink.vue';
import {
  LinkTypeEnum,
  Translation,
  WidgetTypeEnum,
} from '@/types';
import variables from '@/components/template-builder/utils/variables-list';
import {
  translateFromGoogle,
  updateIframeTranslation,
  updateIframeTranslations,
} from '@/components/template-builder/utils/translate';
import {
  Lang,
  UserState,
} from '@/composables/User';
import { useI18n } from 'vue-i18n';
import Panel from 'primevue/panel';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import { refreshSmartProductList } from '@/components/template-builder/callbacks';
import {
  highlightWidgetOnTranslationClicked,
  isDisplayTemplate,
  isEmailTemplate,
} from '../utils/helpers';

interface GroupTranslation {
  translations: Translation[];
  active: boolean;
}

export default defineComponent({
  name: 'TranslationPanel',

  components: {
    SpmButton,
    TabMenu,
    BaseInputText,
    BaseWysiwygEditor,
    BaseImage,
    BaseTextarea,
    BaseLink,
    Panel,
  },

  props: {
    isClosable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  setup() {
    const { t } = useI18n();
    const componentKey = ref(0);

    const defaultLanguage = UserState.activeShop?.langs
      .filter((language: Lang) => language.default)
      .map((language: Lang) => (language.id))[0] ?? 'fr';

    const activeLanguage = ref(defaultLanguage);

    const translations: Ref<Translation[]> = ref(state.template?.translations.slice() ?? []);
    const translationsForActiveLanguage: Ref<Translation[]> = ref([]);

    const languages = UserState.activeShop?.langs.map((language: Lang) => ({ label: language.id })) ?? [];

    const activeIndex = computed(() => languages.map((item) => item.label).indexOf(activeLanguage.value));

    const groupOrder = computed<string[]>(() => {
      const order: string[] = [];
      translationsForActiveLanguage.value.forEach((translation) => {
        if (translation.groupKey && !order.includes(translation.groupKey)) {
          order.push(translation.groupKey);
        }
      });

      return order;
    });

    const groupedTranslations = ref<Record<string, GroupTranslation>>({});

    const getFieldFromType = (type: string, key: string) => {
      switch (type) {
        case 'html': return 'BaseWysiwygEditor';
        case 'image': return 'BaseImage';
        case 'textarea': return 'BaseTextarea';
        case 'link': return 'BaseLink';
        case 'data-redirect': return 'BaseLink';
        case undefined: return 'BaseLink'; // hack for pushNotification link, may be risky
        default: return getWidgetFromTranslationKey(key) === WidgetTypeEnum.TITLE ? 'BaseTextarea' : 'BaseInputText';
      }
    };

    const getPropsFromType = (type: string) => {
      switch (type) {
        case 'text':
          return {
            displayEmojis: true,
            displayVariables: true,
            variablesList: variables.allVariables,
          };
        case 'link':
          // eslint-disable-next-line no-case-declarations
          const options = [LinkTypeEnum.NONE, LinkTypeEnum.WEB_ADDRESS, LinkTypeEnum.EMAIL_ADDRESS, LinkTypeEnum.ANCHOR];
          if (state.template && isDisplayTemplate(state.template.type)) {
            options.push(LinkTypeEnum.DISPLAY_TEMPLATE, LinkTypeEnum.CLOSE_POPIN);
          }
          if (state.template && isEmailTemplate(state.template.type)) {
            options.push(LinkTypeEnum.DISPLAY_TEMPLATE);
          }
          return {
            linkTypeOptions: options,
            variablesList: variables.allVariables,
          };
        case 'data-redirect':
          return {
            linkTypeOptions: [LinkTypeEnum.WEB_ADDRESS, LinkTypeEnum.DISPLAY_TEMPLATE],
            variablesList: variables.allVariables,
          };
        case undefined: // hack for pushNotification link, may be risky
          return {
            linkTypeOptions: [LinkTypeEnum.WEB_ADDRESS, LinkTypeEnum.DISPLAY_TEMPLATE],
            variablesList: variables.allVariables,
          };
        default:
          return {
            displayEmojis: true,
            displayVariables: true,
            variablesList: variables.allVariables,
          };
      }
    };

    const isTranslatableField = (type: string): boolean => {
      switch (type) {
        case 'html': return true;
        case 'image': return false;
        case 'textarea': return true;
        case 'link': return false;
        case 'text': return true;
        case 'placeholder': return true;
        case undefined: return false; // hack for pushNotification link, may be risky
        default: return false;
      }
    };

    const createTranslationsFormLanguage = (language: string) => translations.value
      .filter((translation: Translation) => translation.language === defaultLanguage)
      .map((translation: Translation) => {
        const translationInNewLanguage = { ...translation };
        translationInNewLanguage.language = language;
        return translationInNewLanguage;
      });

    const handleChangeLanguage = (language: string) => {
      translationsForActiveLanguage.value = [];
      activeLanguage.value = language;
      if (state.template) {
        // We restore all smart lists in the template
        restoreSmartListsOriginalCode(null);
        translationsForActiveLanguage.value.push(...translations.value.filter((translation: Translation) => translation.language === activeLanguage.value));
        if (translationsForActiveLanguage.value.length === 0) {
          translationsForActiveLanguage.value = createTranslationsFormLanguage(language);
          // add new translations to component object
          translations.value.push(...translationsForActiveLanguage.value);
          // add new translations in state
          addTranslationsToState(translationsForActiveLanguage.value);
        }
        refreshSmartProductList(null, language);
        updateIframeTranslations(translationsForActiveLanguage.value);
        refreshAttributeInDOM(language);
      }
    };

    handleChangeLanguage(activeLanguage.value);

    const translateValue = async (translation: Translation) => {
      // Get value of default language's translation
      const valueToTranslate = getTranslationByKeyAndLang(translation.key, defaultLanguage);

      if (valueToTranslate) {
        let translationResult = await translateFromGoogle(valueToTranslate.value, translation.language);
        translationResult = translationResult.replace(/\n/gi, '').replace(/<br\s*\/?>/gi, '\n');
        replaceTranslationInState(translation.key, translation.language, translationResult);
        componentKey.value += 1;
        updateIframeTranslation(translation);
        markTemplateAsEdited();
      }
    };

    const handleUpdateModelValue = (translation: any, newValue: any) => {
      replaceTranslationInState(translation.key, translation.language, newValue);
      updateIframeTranslation(translation);
      refreshAttributeInDOM(translation.language, translation.key);
      markTemplateAsEdited();
    };

    onBeforeMount(() => {
      languages.sort((a, b) => {
        if (a.label === defaultLanguage) {
          return -1;
        }
        if (b.label === defaultLanguage) {
          return 1;
        }
        return 0;
      });
    });

    const componentRefs: Ref<Record<string, any>> = ref({});
    const groupComponentRefs: Ref<Record<string, any>> = ref({});
    const highlightTimeout = ref();

    const setComponentRef = (el: any, translationId: string) => {
      if (el) {
        componentRefs.value[translationId] = el;
      }
    };
    const setGroupComponentRef = (el: any, groupKey: string) => {
      if (el) {
        groupComponentRefs.value[groupKey] = el;
      }
    };

    const removeHighlight = () => {
      if (highlightTimeout.value) {
        clearTimeout(highlightTimeout.value);
      }
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const groupKey in groupComponentRefs.value) {
        const componentGroup = groupComponentRefs.value[groupKey];
        componentGroup.classList.remove('spm-input-highlight');
      }
    };

    watch(() => state.selectedTranslationId, () => {
      if (state.selectedTranslationId) {
        const component = componentRefs.value[state.selectedTranslationId];
        if (component && component.focus) {
          removeHighlight();
          const groupKey = component.$attrs['group-key'];
          const componentGroup = groupComponentRefs.value[groupKey];
          if (componentGroup) {
            if ('scrollIntoView' in component.$el) {
              component.$el.scrollIntoView({ behavior: 'smooth', block: 'center' });
            } else if ('scrollIntoView' in component) {
              component.scrollIntoView();
            }
            componentGroup.classList.add('spm-input-highlight');
            highlightTimeout.value = setTimeout(() => {
              componentGroup.classList.remove('spm-input-highlight');
              component.focus();
              highlightTimeout.value = null;
            }, 1200);
            setSelectedTranslationId('');
          }
        }
      }
    });

    watch(() => translationsForActiveLanguage.value, () => {
      const grouped: Record<string, GroupTranslation> = {};

      translationsForActiveLanguage.value.forEach((translation) => {
        if (translation.groupKey) {
          if (!grouped[translation.groupKey]) {
            grouped[translation.groupKey] = {
              translations: [],
              active: false,
            };
          }
          grouped[translation.groupKey].translations.push(translation);
        }
      });

      groupedTranslations.value = grouped;
    }, { deep: true, immediate: true });

    const handleHideToolbar = () => {
      if (state.showConfigurationPanelCallback) {
        state.showConfigurationPanelCallback();
        setShowConfigurationPanelCallback(null);
      } else {
        hideLeftToolbar();
      }
    };

    const handleActivateGroup = (groupKey: string) => {
      groupedTranslations.value[groupKey].active = true;
    };

    const handleDeactivateGroup = (groupKey: string) => {
      groupedTranslations.value[groupKey].active = false;
    };

    onBeforeUnmount(() => {
      if (highlightTimeout.value) {
        clearTimeout(highlightTimeout.value);
      }
      componentRefs.value = {};
      groupComponentRefs.value = {};
    });

    return {
      t,
      languages,
      defaultLanguage,
      activeLanguage,
      activeIndex,
      componentKey,
      groupOrder,
      groupedTranslations,
      handleChangeLanguage,
      getFieldFromType,
      getPropsFromType,
      highlightWidgetOnTranslationClicked,
      translateValue,
      isTranslatableField,
      handleUpdateModelValue,
      setComponentRef,
      handleHideToolbar,
      handleActivateGroup,
      handleDeactivateGroup,
      setGroupComponentRef,
    };
  },
});
</script>

<style lang="scss" scoped>
.icons-header-panel {
  cursor: pointer;
  color: #607D8B;
}

.field-group-content {
  .field-wrapper:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}

.active-group {
  border-color: $brand-color-primary;
  padding-left: 10px !important;
  margin-left: -9px;
}
</style>

<style lang="scss">
.translation-panel {
  &--menu {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
  }
}
</style>
