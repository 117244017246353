<template>
  <div class="configuration-wrapper">
    <Panel>
      <template #header>
        <div class="panel-header-content">
          <h4>{{ t('templateBuilder.toolbar.tooltip.information', null) }}</h4>
        </div>
      </template>

      <template #icons>
        <i
          class="far fa-times icons-header-panel"
          @click="hideLeftToolbar"
        />
      </template>

      <div class="field-group pt-3">
        <div>
          <div class="field-group-content">
            <div class="field-wrapper">
              <label class="block mb-3">
                {{ t('templateBuilder.fields.templateName', null) }}
              </label>
              <div class="p-fluid flex">
                <InputText
                  v-model="name"
                />
              </div>
              <FieldErrors
                :key="componentFieldErrorsKey"
                :errors="formValidationErrors"
                field="name"
              />
            </div>
          </div>
        </div>
      </div>
      <template v-if="isEmailTemplate">
        <div class="field-group pt-3">
          <div>
            <div class="field-group-content">
              <div class="field-wrapper">
                <label class="block mb-3">
                  {{ t('templateBuilder.fields.emailSubject', null) }}
                </label>
                <div class="p-fluid flex">
                  <LocalizedTextInput
                    id="subject"
                    v-model="subject"
                    :display-emojis="true"
                    :display-variables="true"
                    :display-translate="true"
                    :display-ai-button="true"
                    :ai-params="{ aiContentType: 2 }"
                    :variables-list="variables.allVariables"
                    class="w-full"
                  />
                </div>
                <FieldErrors
                  :key="componentFieldErrorsKey"
                  :errors="formValidationErrors"
                  field="subject"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="field-group pt-3">
          <div>
            <div class="field-group-content">
              <div class="field-wrapper">
                <label class="block mb-3">
                  {{ t('templateBuilder.fields.senderEmail', null) }}
                </label>
                <div class="p-fluid flex">
                  <LocalizedTextInput
                    id="fromEmail"
                    v-model="fromEmail"
                    type="auto_complete"
                    :complete="(query) => searchValuesForAutocompletion('senderAddress', query, myDataAutoCompletion)"
                    class="w-full"
                    :continue-reloading="true"
                  />
                </div>
                <span style="font-size: 12px; color: #495057;">
                  {{ t('emailDomainValid.message', null) }}
                  <span
                    style="cursor: pointer; text-decoration: underline; color: blue;"
                    @click="goToDomainPage"
                  >
                    {{ t('emailDomainValid.click', null) }}
                  </span></span>
                <FieldErrors
                  :key="componentFieldErrorsKey"
                  :errors="formValidationErrors"
                  field="fromEmail"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="field-group pt-3">
          <div>
            <div class="field-group-content">
              <div class="field-wrapper">
                <label class="block mb-3">
                  {{ t('templateBuilder.fields.senderName', null) }}
                </label>
                <div class="p-fluid flex">
                  <LocalizedTextInput
                    id="fromName"
                    v-model="fromName"
                    type="auto_complete"
                    :complete="(query) => searchValuesForAutocompletion('senderName', query, myDataAutoCompletion)"
                    class="w-full"
                    :display-emojis="true"
                  />
                </div>
                <FieldErrors
                  :key="componentFieldErrorsKey"
                  :errors="formValidationErrors"
                  field="fromName"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-if="isSmsTemplate">
        <div class="field-group pt-3">
          <div>
            <div class="field-group-content">
              <div class="field-wrapper">
                <label class="block mb-3">
                  {{ t('templateBuilder.fields.senderNameSms', null) }}
                </label>
                <div class="p-fluid flex">
                  <LocalizedTextInput
                    id="fromName"
                    v-model="fromName"
                    :display-emojis="true"
                    type="auto_complete"
                    :complete="(query) => searchValuesForAutocompletion('senderName', query, myDataAutoCompletion)"
                    class="w-full"
                  />
                </div>
                <FieldErrors
                  :key="componentFieldErrorsKey"
                  :errors="formValidationErrors"
                  field="fromName"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </Panel>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent, onMounted,
  Ref,
  ref,
  watch,
} from 'vue';
import InputText from 'primevue/inputtext';
import Panel from 'primevue/panel';
import { useI18n } from 'vue-i18n';
import variables from '@/components/template-builder/utils/variables-list';
import {
  hideLeftToolbar,
  markTemplateAsEdited,
  TemplateEditorState as state,
  togglePanel,
  updateTemplateInformations,
} from '@/composables/template-editor/TemplateEditor';
import {
  StringMap,
  TemplateInformations,
  TemplateParentTypeEnum,
} from '@/types';
import { getShopDefaultLang, Lang, UserState } from '@/composables/User';
import { getTemplateParentTypeByType } from '@/components/template-builder/utils/helpers';
import LocalizedTextInput from '@/components/fields/LocalizedTextInput.vue';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import {
  loadSenderAddresses,
  loadSenderNames,
  searchValuesForAutocompletion,
} from '@/composables/autoCompletionValuesMedia';
import getShopsDomains from '@/composables/shop/ShopsSendDomains';
import { OperatorType } from '@/types/generated-types/graphql';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'InformationPanel',

  components: {
    FieldErrors,
    LocalizedTextInput,
    InputText,
    Panel,
  },

  props: {
    formValidation: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  setup(props: { formValidation: any }) {
    const { t } = useI18n();
    const router = useRouter();
    const name: Ref<string> = ref(state.template?.informations.name ?? '');
    const languages: string[] = UserState.activeShop?.langs.map((language: Lang) => language.id) ?? [];
    const defaultLang: string = state.template?.informations.lang ?? '';
    const defaultLanguage: string = getShopDefaultLang();
    const subject: Ref<StringMap> = ref({});
    const fromEmail: Ref<StringMap> = ref({});
    const fromName: Ref<StringMap> = ref({});

    const formValidationErrors = ref({});
    const componentFieldErrorsKey = ref(0);

    languages.forEach((language: string) => {
      if (getTemplateParentTypeByType(state.template?.type ?? '') === TemplateParentTypeEnum.EMAIL) {
        if (state.template?.informations.subject) {
          subject.value[language] = state.template?.informations.subject[language] ?? state.template?.informations.subject[defaultLanguage] ?? '';
        }

        if (state.template?.informations.fromEmail) {
          fromEmail.value[language] = state.template?.informations.fromEmail[language] ?? state.template?.informations.fromEmail[defaultLanguage] ?? '';
        }

        if (state.template?.informations.fromName) {
          fromName.value[language] = state.template?.informations.fromName[language] ?? state.template?.informations.fromName[defaultLanguage] ?? '';
        }
      } else if (getTemplateParentTypeByType(state.template?.type ?? '') === TemplateParentTypeEnum.SMS && state.template?.informations.fromName) {
        fromName.value[language] = state.template?.informations.fromName[language] ?? state.template?.informations.fromName[defaultLanguage] ?? '';
      }
    });

    const isEmailTemplate = computed(() => getTemplateParentTypeByType(state.template?.type ?? '') === TemplateParentTypeEnum.EMAIL);
    const isSmsTemplate = computed(() => getTemplateParentTypeByType(state.template?.type ?? '') === TemplateParentTypeEnum.SMS);

    watch([name, subject.value, fromEmail.value, fromName.value], () => {
      const informations: TemplateInformations = {
        name: name.value,
        lang: defaultLang,
      };

      if (getTemplateParentTypeByType(state.template?.type ?? '') === TemplateParentTypeEnum.EMAIL) {
        informations.subject = subject.value;
        informations.fromEmail = fromEmail.value;
        informations.fromName = fromName.value;
      } else if (getTemplateParentTypeByType(state.template?.type ?? '') === TemplateParentTypeEnum.SMS) {
        informations.fromName = fromName.value;
      }

      updateTemplateInformations(informations);
      markTemplateAsEdited();
    });

    const goToDomainPage = () => {
      const routePath = router.resolve({ path: '/shop/send-domains' }).href;
      window.open(routePath, '_blank');
      togglePanel('informationPanel');
    };

    const myDataAutoCompletion: Ref<any> = ref({
      senderNames: [],
      senderAddresses: [],
      domainsOfShop: [],
    });

    onMounted(async () => {
      myDataAutoCompletion.value.senderNames = await loadSenderNames(state.template?.type ?? 'email');

      if (state.template?.type === 'email') {
        myDataAutoCompletion.value.senderAddresses = await loadSenderAddresses();
        myDataAutoCompletion.value.domainsOfShop = await getShopsDomains(0, [{
          field: 'domain_verified',
          operator: OperatorType.Equals,
          value: true,
        }]);
      }

      formValidationErrors.value = props.formValidation;
      componentFieldErrorsKey.value += 1;
    });

    watch(() => props.formValidation, () => {
      formValidationErrors.value = props.formValidation;
      componentFieldErrorsKey.value += 1;
    });

    return {
      t,
      name,
      subject,
      fromEmail,
      fromName,
      variables,
      isEmailTemplate,
      isSmsTemplate,
      hideLeftToolbar,
      formValidationErrors,
      componentFieldErrorsKey,
      myDataAutoCompletion,
      searchValuesForAutocompletion,
      goToDomainPage,
    };
  },
});
</script>

<style lang="scss" scoped>
.icons-header-panel {
  cursor: pointer;
  color: #607D8B;
}

.field-group-content {
  .field-wrapper:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}
</style>
