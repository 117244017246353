import {
  ConfigurationPanelStructure,
  ConfigurationPanelStructureItemEnum,
  TemplateStructureEnum,
  TabItem,
  BackgroundImageOptionTypeEnum,
} from '@/types';
import bordersFieldConfig from '@/components/template-builder/config/fields/borders-config';
import getVerticalSpacesFieldConfig from '@/components/template-builder/config/fields/vertical-spaces-config';
import getColorFieldConfig from '@/components/template-builder/config/fields/color-config';
// eslint-disable-next-line import/no-cycle
import {
  setCssProperty,
  setCssPropertyAndRemoveIfZero,
  setMediaDisplay,
  setBackgroundImage,
  toggleClassAndRemoveFromChild,
} from '@/components/template-builder/setters';
// eslint-disable-next-line import/no-cycle
import {
  getClassByName,
  getCssPropertyByName,
  getBackgroundImageProperties,
  getBackgroundImageValue, hasClassElementAndChild,
} from '@/components/template-builder/getters';
import mediaDisplayFieldConfig from '@/components/template-builder/config/fields/media-display-config';
import getDisplayFieldConfig from '@/components/template-builder/config/fields/display-config';
import getBackgroundImageFieldConfig from '@/components/template-builder/config/fields/background-image-config';
// eslint-disable-next-line import/no-cycle
import { refreshSmartProductList } from '@/components/template-builder/callbacks';
// eslint-disable-next-line import/no-cycle
import { restoreSmartListsOriginalCode } from '@/composables/template-editor/TemplateEditor';

export const sectionTabItemConfig: TabItem = {
  type: ConfigurationPanelStructureItemEnum.TAB_ITEM,
  tabItemType: TemplateStructureEnum.SECTION,
  label: 'templateBuilder.configs.section',
  selector: 'body table#spm_body #{ID} .container',
  tabPanel: {
    label: 'templateBuilder.configs.section',
    items: [
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.widgetStyles',
        items: [
          {
            label: 'templateBuilder.fields.backgroundColorFullWidth',
            selector: 'body table#spm_body #{ID}',
            properties: [{ name: 'background-color', getter: getCssPropertyByName, setters: [setCssProperty] }],
            ...getColorFieldConfig('#ffffff'),
          },
          {
            label: 'templateBuilder.fields.backgroundColorCentralArea',
            selector: 'body table#spm_body #{ID} .container',
            properties: [{ name: 'background-color', getter: getCssPropertyByName, setters: [setCssProperty] }],
            ...getColorFieldConfig(),
          },
          {
            label: 'templateBuilder.fields.backgroundImage',
            selector: 'body table#spm_body #{ID}',
            properties: [
              { name: 'background-image', getter: getBackgroundImageValue, setters: [setBackgroundImage] },
              { name: 'backgroundImageCustomSelector', getter: getBackgroundImageProperties, setters: [] },
            ],
            ...getBackgroundImageFieldConfig(BackgroundImageOptionTypeEnum.SELECTOR),
          },
          {
            label: 'templateBuilder.fields.sectionMargins',
            selector: 'body table#spm_body #{ID} > tbody > tr > td',
            properties: [
              { name: 'padding-top', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-bottom', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
            ],
            ...getVerticalSpacesFieldConfig('padding'),
          },
          {
            label: 'templateBuilder.fields.bordersBack',
            selector: 'body table#spm_body #{ID}',
            properties: [
              { name: 'border-color', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-style', getter: getCssPropertyByName, setters: [] },
              { name: 'border-width', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'border-bottom-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-bottom-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-bottom-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-top-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-top-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-top-width', getter: getCssPropertyByName, setters: [] },
            ],
            ...bordersFieldConfig,
            options: {
              ...bordersFieldConfig.options,
              defaultColor: '#000000',
            },
          },
          {
            label: 'templateBuilder.fields.bordersCentralArea',
            selector: 'body table#spm_body #{ID} .container',
            properties: [
              { name: 'border-color', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-style', getter: getCssPropertyByName, setters: [] },
              { name: 'border-width', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'border-bottom-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-bottom-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-bottom-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-top-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-top-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-top-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-left-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-left-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-left-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-right-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-right-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-right-width', getter: getCssPropertyByName, setters: [] },
            ],
            ...bordersFieldConfig,
          },
          {
            label: 'templateBuilder.fields.responsiveRow',
            selector: 'body table#spm_body #{ID} .row',
            displayToggle: false,
            properties: [{
              // eslint-disable-next-line max-len
              name: 'spm_responsive', getter: hasClassElementAndChild, setters: [toggleClassAndRemoveFromChild], preCallbacks: [restoreSmartListsOriginalCode], callbacks: [refreshSmartProductList],
            }],
            ...getDisplayFieldConfig('spm_responsive', ''),
          },
          {
            label: 'templateBuilder.fields.mediaDisplay',
            selector: 'body table#spm_body #{ID}',
            properties: [{ name: 'show-for-', getter: getClassByName, setters: [setMediaDisplay] }],
            ...mediaDisplayFieldConfig,
          },
        ],
      },
    ],
  },
};

const sectionItemConfig: ConfigurationPanelStructure = {
  items: [
    sectionTabItemConfig,
  ],
};

export default sectionItemConfig;
