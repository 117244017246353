import { i18n } from '@/i18n';
// eslint-disable-next-line import/no-cycle
import { store } from '@/store';
import { ActiveLiveEditorItemData, HistoryType, TemplateStructureEnum } from '@/types';

// eslint-disable-next-line import/no-cycle
import {
  refreshSectionsConfig,
  removeActiveStyles,
  removeHoverStyles,
  removeSortableStyles,
  removeUnusedTranslations,
  resetActiveSection,
  setActiveSection,
  updateSectionsInState,
  removeCustomWidgetCss,
  TemplateEditorState as state,
  showColumnWidgetModal,
  markTemplateAsEdited,
} from '@/composables/template-editor/TemplateEditor';
// eslint-disable-next-line import/no-cycle
import { resetListeners } from '@/components/template-builder/utils/listeners';
// eslint-disable-next-line import/no-cycle
import { checkIfSavePointNeeded, createHistory } from '@/composables/template-editor/History';
import { computed, ComputedRef } from 'vue';
// eslint-disable-next-line import/no-cycle
import { showAIModal } from '@/composables/services/ShopimindAI';
import {
  ACTIONS_BUTTON_GROUP, AI_BUTTON_IDENTIFIER,
  BUILDER_ELEMENT_ACTIVE_CLASS,
  DUPLICATE_BUTTON_IDENTIFIER,
  EDIT_COLUMN_BUTTON_IDENTIFIER,
  REMOVE_BUTTON_IDENTIFIER,
  SAVE_BUTTON_IDENTIFIER,
  TEMPLATE_SECTION_CLASS,
  TEMPLATE_SECTION_IDENTIFIER, WIDGET_DRAGGABLE_CLASS,
} from './constants';
// eslint-disable-next-line import/no-cycle
import {
  createCustomButton,
  decorateActiveItemElementWithStyles,
  decorateElementWithStyles,
  getTemplateIframeDocument,
  getWidgetType,
  isDisplayTemplate,
} from './helpers';
import {
  faAiAltRegular, faCloneRegular, faColumn, faSaveRegular, faTrashAltRegular,
} from './icons-definition';
import {
  actionsButtonGroupStyles,
  AIButtonStyles,
  columnActionsButtonGroupStyles,
  secondaryButtonStyles,
  sectionActionsButtonGroupStyles,
} from './elements-style-definitions';
// eslint-disable-next-line import/no-cycle
import {
  onDuplicateLine, onDuplicateSection, onDuplicateWidget, onRemoveElement,
  removeColumn,
} from './action-buttons-handlers';

let translation: any;
(async () => {
  translation = await i18n;
})();

const createRemoveButton = (activeItemData: ActiveLiveEditorItemData, onClickHandler: ((event: Event) => void) | null = null) => {
  const customButtonData = {
    id: `${activeItemData.type}-${REMOVE_BUTTON_IDENTIFIER}`,
    title: translation.global.t('templateLiveView.actionsButtonGroup.remove'),
    children: faTrashAltRegular,
    styles: secondaryButtonStyles,
    onClick: (e: Event) => {
      e.stopPropagation();
      const template = getTemplateIframeDocument();
      const element = template?.querySelector(activeItemData.selector);

      if (element) {
        checkIfSavePointNeeded().then(() => {
          const section = (element.matches(TEMPLATE_SECTION_IDENTIFIER)) ? element : element?.closest('[data-spmelementid]');

          if (section) {
            const sectionId = section.getAttribute('data-spmelementid');

            if (sectionId) {
              // Mark section as active
              setActiveSection(parseInt(sectionId, 10));
            }
          }

          onRemoveElement(activeItemData.selector);

          let historyType: HistoryType | null;
          switch (activeItemData.type) {
            case TemplateStructureEnum.WIDGET:
              historyType = HistoryType.DELETE_WIDGET;
              break;
            case TemplateStructureEnum.COLUMN:
              historyType = HistoryType.DELETE_COLUMN;
              break;
            case TemplateStructureEnum.SECTION:
              historyType = HistoryType.DELETE_SECTION;
              break;
            default:
              historyType = null;
              break;
          }

          // Update state
          updateSectionsInState();

          if (historyType) {
            createHistory(historyType);
          }
          removeUnusedTranslations();
          removeCustomWidgetCss(activeItemData.selector);

          refreshSectionsConfig();

          resetListeners();
          resetActiveSection();
          store.commit('liveEditor/resetSelectedStructure');
          markTemplateAsEdited();
        });
      }
    },
  };
  if (onClickHandler) {
    customButtonData.onClick = onClickHandler;
  }
  return createCustomButton(customButtonData);
};

const createDuplicateButton = (prefix: string, onClickHandler: (event: Event) => void) => createCustomButton({
  id: `${prefix}-${DUPLICATE_BUTTON_IDENTIFIER}`,
  title: translation.global.t('templateLiveView.actionsButtonGroup.duplicate'),
  children: faCloneRegular,
  styles: secondaryButtonStyles,
  onClick: onClickHandler,
});

const createEditColumnButton = (prefix: string, onClickHandler: (event: Event) => void) => createCustomButton({
  id: `${prefix}-${EDIT_COLUMN_BUTTON_IDENTIFIER}`,
  title: translation.global.t('templateLiveView.actionsButtonGroup.editColumnSize'),
  children: faColumn,
  styles: secondaryButtonStyles,
  onClick: onClickHandler,
});

const createAIButton = (activeItemData: ActiveLiveEditorItemData) => {
  const template = getTemplateIframeDocument();
  const element = template?.querySelector(activeItemData.selector);
  let AIContentType = 0;
  const AIApplyContentParams = {
    selector: '',
    type: '',
    target: '',
    attr: '',
    imageWidth: 0,
    imageHeight: 0,
    idTemplate: 0,
  };

  if (element) {
    const classOfElement = Array.from(element.classList).filter((className) => className !== WIDGET_DRAGGABLE_CLASS.replace('.', '') && className !== 'spm_widget');
    const widgetType = getWidgetType(classOfElement[0]);

    if (state.template) {
      AIApplyContentParams.idTemplate = state.template.id;
    }

    switch (widgetType) {
      case 'widget-text':
        AIContentType = 1;
        AIApplyContentParams.selector = `${activeItemData.selector} .spm_rich_editor`;
        AIApplyContentParams.type = 'html';
        AIApplyContentParams.target = 'template';
        break;
      case 'widget-image':
        AIContentType = 3;
        AIApplyContentParams.selector = `${activeItemData.selector} img`;
        AIApplyContentParams.type = 'attr';
        AIApplyContentParams.attr = 'src';
        AIApplyContentParams.target = 'template';
        break;
      default: return false;
    }
  }
  if (AIContentType) {
    return createCustomButton({
      id: `${activeItemData.type}-${AI_BUTTON_IDENTIFIER}`,
      title: translation.global.t('templateLiveView.actionsButtonGroup.ai'),
      children: faAiAltRegular,
      styles: AIButtonStyles,
      onClick: (e: Event) => {
        showAIModal(e, AIContentType, AIApplyContentParams);
      },
    });
  }
  return false;
};

const getActionsButtonListByItemType = (activeItemData: ActiveLiveEditorItemData) => {
  const { type: itemType } = activeItemData;
  const actionsButtonList: Array<HTMLDivElement> = [];

  const saveSectionButton = createCustomButton({
    id: `${itemType}-${SAVE_BUTTON_IDENTIFIER}`,
    title: translation.global.t('templateLiveView.actionsButtonGroup.save'),
    children: faSaveRegular,
    styles: secondaryButtonStyles,
    onClick: (e: Event) => {
      e.stopPropagation();
      store.commit('liveEditor/showSaveSectionModal', activeItemData.selector);
    },
  });

  const template = getTemplateIframeDocument();

  // eslint-disable-next-line default-case
  switch (itemType) {
    case TemplateStructureEnum.WIDGET:
      actionsButtonList.push(
        createDuplicateButton(itemType, (e: Event) => {
          e.stopPropagation();
          onDuplicateWidget(activeItemData);
          markTemplateAsEdited();
        }),
        createRemoveButton(activeItemData),
      );
      // eslint-disable-next-line no-case-declarations
      const AIButton = createAIButton(activeItemData);
      if (AIButton) {
        actionsButtonList.push(AIButton);
      }
      if (isDisplayTemplate(state.template?.type)) {
        // Get column parent of widget
        const column = template.querySelector(activeItemData.selector)?.closest('.spm_column');
        if (column) {
          actionsButtonList.push(
            createEditColumnButton(itemType, (e: Event) => {
              e.stopPropagation();
              store.commit('liveEditor/setSelectedColumnId', `#${column.id}`);
              showColumnWidgetModal();
            }),
          );
        }
      }
      break;
    case TemplateStructureEnum.COLUMN:
      actionsButtonList.push(
        createEditColumnButton(itemType, (e: Event) => {
          e.stopPropagation();
          store.commit('liveEditor/setSelectedColumnId', activeItemData.selector);
          showColumnWidgetModal();
        }),
        createRemoveButton(activeItemData, (e: Event) => {
          e.stopPropagation();
          const columnToRemove = template.querySelector(activeItemData.selector);
          if (columnToRemove) {
            removeColumn(e, columnToRemove as HTMLElement);
            markTemplateAsEdited();
          }
        }),
      );
      break;
    case TemplateStructureEnum.LINE:
      actionsButtonList.push(
        createDuplicateButton(itemType, (e: Event) => {
          e.stopPropagation();
          onDuplicateLine(activeItemData);
        }),
        createRemoveButton(activeItemData),
      );
      break;
    case TemplateStructureEnum.SECTION:
      // Count sections of template. If there is only one section, we do not add the remove button
      if (template.querySelectorAll(`.${TEMPLATE_SECTION_CLASS}`).length === 1) {
        actionsButtonList.push(
          saveSectionButton,
          createDuplicateButton(itemType, (e: Event) => {
            e.stopPropagation();
            onDuplicateSection(activeItemData);
            markTemplateAsEdited();
          }),
        );
      } else {
        actionsButtonList.push(
          saveSectionButton,
          createDuplicateButton(itemType, (e: Event) => {
            e.stopPropagation();
            onDuplicateSection(activeItemData);
            markTemplateAsEdited();
          }),
          createRemoveButton(activeItemData),
        );
      }
      break;
  }

  return actionsButtonList;
};

const getActionsButtonGroupStylesByItemType = (itemType: TemplateStructureEnum) => {
  switch (itemType) {
    case TemplateStructureEnum.COLUMN:
      return columnActionsButtonGroupStyles;
    case TemplateStructureEnum.SECTION:
      return sectionActionsButtonGroupStyles;
    default:
      return actionsButtonGroupStyles;
  }
};

export const createActionsButtonGroup = (activeItemData: ActiveLiveEditorItemData) => {
  const { type }: {type: TemplateStructureEnum} = activeItemData;
  const actionsButtonGroup = document.createElement('div');
  const actionButtonList = getActionsButtonListByItemType(activeItemData);
  const styles = getActionsButtonGroupStylesByItemType(type);

  actionsButtonGroup.id = `${type}-${ACTIONS_BUTTON_GROUP}`;
  actionsButtonGroup.className = ACTIONS_BUTTON_GROUP;

  decorateElementWithStyles(actionsButtonGroup, styles);

  actionButtonList.forEach((actionButton) => {
    actionsButtonGroup.appendChild(actionButton);
  });

  return actionsButtonGroup;
};

export const removeActiveItemElementDecorations = () => {
  const { activeItemData } = store.state.liveEditor;
  if (!activeItemData) { return; }

  const activeItemElement = decorateActiveItemElementWithStyles(activeItemData.selector, 'remove');
  if (activeItemElement) {
    const actionsButtonGroup = activeItemElement.querySelector(`.${ACTIONS_BUTTON_GROUP}`);
    if (actionsButtonGroup) {
      actionsButtonGroup.remove();
    }

    removeHoverStyles();
    removeActiveStyles();
    removeSortableStyles();
  }
  store.commit('liveEditor/resetActiveItemData');
};

export const setActiveItemData = (activeItemData: ActiveLiveEditorItemData) => {
  const currentActiveItemData: ComputedRef<ActiveLiveEditorItemData | null> = computed(() => store.getters['liveEditor/getActiveItemData']);

  if (!currentActiveItemData.value || currentActiveItemData.value.selector !== activeItemData.selector) {
    removeActiveItemElementDecorations();
    store.commit('liveEditor/setActiveItemData', activeItemData);
  }
};

export const addActiveItemElementDecorations = (activeItemData: ActiveLiveEditorItemData) => {
  setActiveItemData(activeItemData);

  // We create actions toolbar if the element doesn't contain one
  const template = getTemplateIframeDocument();
  let activeItemElement: HTMLElement | null = template.querySelector(activeItemData.selector);

  if (activeItemElement && !activeItemElement.classList.contains(BUILDER_ELEMENT_ACTIVE_CLASS)) {
    activeItemElement = decorateActiveItemElementWithStyles(activeItemData.selector, 'add');
  }

  if (
    !state.leftToolbar.show.translationPanel
    && activeItemElement
    && !activeItemElement.querySelector(`.${ACTIONS_BUTTON_GROUP}`)
    && (activeItemData.type !== TemplateStructureEnum.COLUMN || (activeItemData.type === TemplateStructureEnum.COLUMN && isDisplayTemplate(state.template?.type)))
  ) {
    const actionButtonGroup = createActionsButtonGroup(activeItemData);
    activeItemElement.appendChild(actionButtonGroup);
    actionButtonGroup.style.display = 'block';
  }
};
