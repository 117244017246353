<template>
  <div>
    <div class="flex justify-content-between flex-wrap">
      <div
        v-if="configs.label !== ''"
        class="flex justify-content-center"
      >
        <label class="block my-3">
          {{ t(configs.label) }}
        </label>
      </div>
      <div class="flex justify-content-center align-items-center">
        <ToggleDisplaySettings
          v-if="!Object.prototype.hasOwnProperty.call(configs, 'displayToggle') || configs.displayToggle"
          :configs="configs"
          :parser-values="parserValues"
          @display-settings="(value) => displayProperties = value"
        />
      </div>
    </div>
    <div
      class="align-items-center stepperPxPercentSlider"
      :class="{ 'hidden': !displayProperties, 'flex': displayProperties }"
    >
      <BaseInputNumber
        v-model="currentValue"
        :min="minValue"
        :max="maxValue"
        class="value-input"
        @input="emitParserValueChanged"
      />
      <BaseDropdown2
        v-model="currentUnit"
        :options="unitOptions"
        class="unit-input"
        @update:model-value="emitParserValueChanged"
      />
      <Slider
        ref="slider"
        v-model="currentValue"
        :step="sliderStep"
        :min="minValue"
        :max="maxValue"
        class="slider"
        @change="emitParserValueChanged"
        @mousedown="handleSlideStart"
        @mouseup="handleSlideEnd"
        @slideend="handleSlideEnd"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  ref,
  computed,
  toRefs,
  watch,
  onBeforeMount, onMounted,
} from 'vue';
import { useI18n } from 'vue-i18n';
import Slider from 'primevue/slider';

import {
  StepperPxPercentSliderFieldConfig,
  StepperPxPercentSliderOptionKeys,
  StepperPxPercentSliderFieldOption,
  ParserFieldObject,
  Property,
} from '@/types';
import { useStore } from '@/store';
import { getElementFromIframe, isEmailTemplate } from '@/components/template-builder/utils/helpers';
import BaseInputNumber from '@/components/fields/partials/BaseInputNumber.vue';
import BaseDropdown2 from '@/components/fields/BaseDropdown2.vue';
import ToggleDisplaySettings from '@/components/template-builder/fields/partials/ToggleDisplaySettings.vue';
import { getFirstParent } from '@/helpers';
import { TemplateEditorState } from '@/composables/template-editor/TemplateEditor';

export default defineComponent({
  name: 'StepperPxPercentSlider',

  components: {
    ToggleDisplaySettings,
    Slider,
    BaseInputNumber,
    BaseDropdown2,
  },

  props: {
    configs: {
      type: Object as PropType<StepperPxPercentSliderFieldConfig>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserFieldObject>,
      required: true,
    },
  },

  emits: ['on-change-properties'],

  setup(props, context) {
    const { t } = useI18n();
    const store = useStore();
    const configs = ref(props.configs);
    const displayProperties = ref(!(!Object.prototype.hasOwnProperty.call(configs.value, 'displayToggle') || configs.value.displayToggle === true));
    const { options } = toRefs(props.configs);
    const fallbackUnit = 'px';
    const currentValue = ref(0);
    const currentUnit = ref(fallbackUnit);
    const sliderStep = ref(1);
    const minValue = ref(0);
    const maxValue = ref(100);
    const isMounted = ref(false); // Variable de contrôle
    const unitOptions = computed(() => Object.keys(options.value).map((option) => ({
      label: option,
      value: option,
    })));
    const slider = ref();
    const isSliderDragged = computed(() => store.getters['liveEditor/getIsSliderDragged']);

    const defaultUnit = computed(() => {
      const entry = Object.entries(options.value).find(([, value]) => value?.isDefaultUnitSelected);
      if (entry) {
        return entry[0];
      }
      return fallbackUnit;
    });

    const defaultValue = computed(() => {
      const key = defaultUnit.value as StepperPxPercentSliderOptionKeys;
      return options.value[key]?.defaultValue || 0;
    });

    const mainProperty = computed(() => {
      const { properties } = props.configs;
      return properties && properties.length && properties[0];
    });

    const parserValueWithUnits = computed(() => {
      if (mainProperty.value) {
        return mainProperty.value.value;
      }

      return undefined;
    });

    onMounted(() => {
      isMounted.value = true; // Le composant est monté
    });
    const emitParserValueChanged = () => {
      if(isMounted.value) {
        const changedValues: Array<Property> = [];
        const { properties } = props.configs;

        if (properties) {
          properties.forEach((property) => {
            const updatedProperty = property;
            // eslint-disable-next-line no-template-curly-in-string
            updatedProperty.value = `${currentValue.value}${currentUnit.value}`;
            changedValues.push(updatedProperty);
          });

          if (displayProperties.value) {
            context.emit('on-change-properties', {
              selector: props.parserValues.selector,
              properties: changedValues,
            });
          }
        }
      }
    };

    const handleSlideStart = () => {
      store.commit('liveEditor/setIsSliderDragged', true);
    };
    const handleSlideEnd = () => {
      store.commit('liveEditor/setIsSliderDragged', false);
      if (slider.value) {
        slider.value.dragging = false;
      }
    };
    watch(currentUnit, (newValue, oldValue) => {
      if (newValue && oldValue) {
        const oldMaxValue = maxValue.value;

        const currentOptions: StepperPxPercentSliderFieldOption | undefined = options.value[currentUnit.value as StepperPxPercentSliderOptionKeys];
        if (currentOptions) {
          sliderStep.value = currentOptions.step;
          minValue.value = currentOptions.min;
          if (currentOptions.max === 'PARENT_WIDTH') {
            const element = getElementFromIframe(props.parserValues.selector);
            let widthWithoutPaddingAndBorder = defaultValue.value;
            if (element.parentElement) {
              const parentElementStyle = window.getComputedStyle(element.parentElement);
              // eslint-disable-next-line max-len
              widthWithoutPaddingAndBorder = element.parentElement.getBoundingClientRect().width - parseFloat(parentElementStyle.borderLeftWidth) - parseFloat(parentElementStyle.borderRightWidth) - parseFloat(parentElementStyle.paddingLeft) - parseFloat(parentElementStyle.paddingRight);
            }
            maxValue.value = Math.ceil(widthWithoutPaddingAndBorder);
            if (element && element.tagName.toLowerCase() === 'img') {
              // If element is an image, we get the widget width and compare to image natural width to keep the lowest
              const image = element as HTMLImageElement;
              const imgWidth = image.naturalWidth;
              const parent = getFirstParent(element, (isEmailTemplate(TemplateEditorState.template?.type) ? 'th' : 'div'));
              let widthWithoutPaddingAndBorder2 = imgWidth;
              if (parent && parent.offsetWidth < imgWidth) {
                const parentElementStyle2 = window.getComputedStyle(parent);
                // eslint-disable-next-line max-len
                widthWithoutPaddingAndBorder2 = parent.getBoundingClientRect().width - parseFloat(parentElementStyle2.borderLeftWidth) - parseFloat(parentElementStyle2.borderRightWidth) - parseFloat(parentElementStyle2.paddingLeft) - parseFloat(parentElementStyle2.paddingRight);
              }
              maxValue.value = Math.ceil(widthWithoutPaddingAndBorder2);
            }
          } else {
            maxValue.value = currentOptions.max as number;
          }
        }

        if (newValue === '%') {
          // If we switch to percent, we set the correct current value according to previous size in pixels
          currentValue.value = Math.round((currentValue.value * 100) / oldMaxValue);

          if (currentValue.value > 100) {
            // In case there is a calculation error, and we get more than 100%, we set 100 as current value
            currentValue.value = 100;
          }
        } else {
          // If we switch to pixels, we calculate the correct size in pixels according to previous percentage
          currentValue.value = Math.round((currentValue.value * maxValue.value) / 100);
        }
        // Emit changes to update live iframe
        emitParserValueChanged();
      }
    });

    watch(() => isSliderDragged.value, () => {
      if (!isSliderDragged.value && slider.value) {
        slider.value.dragging = false;
      }
    });

    const initOrUpdateConfiguration = () => {
      if (!currentValue.value) {
        currentValue.value = defaultValue.value;
        currentUnit.value = defaultUnit.value;
        if (parserValueWithUnits.value) {
          const matchedGroups = /^(\d+)(px|%)$/g.exec(parserValueWithUnits.value);
          if (matchedGroups && matchedGroups[1] && matchedGroups[2]) {
            currentValue.value = Number(matchedGroups[1]);
            // eslint-disable-next-line
            currentUnit.value = matchedGroups[2];
          }
        }
      }
      const currentOptions: StepperPxPercentSliderFieldOption | undefined = options.value[currentUnit.value as StepperPxPercentSliderOptionKeys];
      if (currentOptions) {
        sliderStep.value = currentOptions.step;
        minValue.value = currentOptions.min;
        if (currentOptions.max === 'PARENT_WIDTH') {
          const element = getElementFromIframe(props.parserValues.selector);
          let widthWithoutPaddingAndBorder = defaultValue.value;
          if (element) {
            if (element.parentElement) {
              const parentElementStyle = window.getComputedStyle(element.parentElement);
              // eslint-disable-next-line max-len
              widthWithoutPaddingAndBorder = element.parentElement.getBoundingClientRect().width - parseFloat(parentElementStyle.borderLeftWidth) - parseFloat(parentElementStyle.borderRightWidth) - parseFloat(parentElementStyle.paddingLeft) - parseFloat(parentElementStyle.paddingRight);
            }
            maxValue.value = Math.ceil(widthWithoutPaddingAndBorder);

            if (element && element.tagName.toLowerCase() === 'img') {
              const image = element as HTMLImageElement;
              const imgWidth = image.naturalWidth;
              // If element is an image, we get the widget width and compare to image natural width to keep the lowest
              const parent = getFirstParent(element, (isEmailTemplate(TemplateEditorState.template?.type) ? 'th' : 'div'));
              let widthWithoutPaddingAndBorder2 = imgWidth;
              if (parent && parent.offsetWidth < imgWidth) {
                const parentElementStyle2 = window.getComputedStyle(parent);
                // eslint-disable-next-line max-len
                widthWithoutPaddingAndBorder2 = parent.getBoundingClientRect().width - parseFloat(parentElementStyle2.borderLeftWidth) - parseFloat(parentElementStyle2.borderRightWidth) - parseFloat(parentElementStyle2.paddingLeft) - parseFloat(parentElementStyle2.paddingRight);
              }
              maxValue.value = Math.ceil(widthWithoutPaddingAndBorder2);
            }
          } else {
            maxValue.value = Math.ceil(widthWithoutPaddingAndBorder);
          }
        } else {
          maxValue.value = currentOptions.max as number;
        }
      }
    };

    onBeforeMount(() => {
      initOrUpdateConfiguration();
    });
    watch(parserValueWithUnits, () => {
      initOrUpdateConfiguration();
    });
    return {
      t,
      displayProperties,
      currentValue,
      currentUnit,
      minValue,
      maxValue,
      unitOptions,
      sliderStep,
      slider,
      emitParserValueChanged,
      handleSlideStart,
      handleSlideEnd,
    };
  },
});
</script>

<style lang="scss">
.stepperPxPercentSlider {
  .p-inputnumber {
    margin-right: 0px !important;
    .p-inputnumber-button-up {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }

  .p-dropdown {
    height: 2.5rem;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-left: none !important;
  }
}
</style>

<style lang="scss" scoped>
.value-input,
.unit-input {
  margin-right: .7rem;
}

.value-input :deep() .p-inputnumber-input.p-inputtext {
  width: 3rem;
}

.unit-input {
  width: 3rem;
}

.slider {
  min-width: 6rem;
  flex-grow: 1;
  margin-left: 2px;

  &.p-slider.p-slider-horizontal :deep() {
    .p-slider-handle {
      $dimension: .7rem;
      $margin:  calc(-1*(#{$dimension} / 2));

      width: $dimension;
      height: $dimension;
      margin-left: $margin;
      margin-top: $margin;
      background-color: $brand-color-primary;
      border-color: $brand-color-primary;
    }

    .p-slider-range {
      background-color: $brand-color-primary;
    }
  }
}
</style>
