import { AlignValueEnum, FieldConfig } from '@/types';
import getElementAlignFieldConfig from '@/components/template-builder/config/fields/element-align-config';
import getWidthFieldConfig from '@/components/template-builder/config/fields/width-config';
// eslint-disable-next-line import/no-cycle
import {
  setAttribute, setCssProperty, setCssPropertyAndRemoveIfZero, setMediaDisplay,
} from '@/components/template-builder/setters';
// eslint-disable-next-line import/no-cycle
import { getAttributeByName, getClassByName, getCssPropertyByName } from '@/components/template-builder/getters';
import mediaDisplayFieldConfig from '@/components/template-builder/config/fields/media-display-config';
import bordersFieldConfig from '@/components/template-builder/config/fields/borders-config';

const widgetSeparatorFieldsConfig: Array<FieldConfig> = [
  {
    label: 'templateBuilder.fields.style',
    selector: '{SELECTOR}.spm_widget_separator .spm_separator th',
    properties: [
      { name: 'border-color', getter: getCssPropertyByName, setters: [setCssProperty] },
      { name: 'border-width', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
      { name: 'border-style', getter: getCssPropertyByName, setters: [] },
      { name: 'border-bottom-style', getter: getCssPropertyByName, setters: [setCssProperty] },
      { name: 'border-bottom-color', getter: getCssPropertyByName, setters: [] },
      { name: 'border-bottom-width', getter: getCssPropertyByName, setters: [] },
    ],
    ...bordersFieldConfig,
  },
  {
    label: 'templateBuilder.fields.width',
    selector: '{SELECTOR}.spm_widget_separator > tbody > tr > th table.spm_separator',
    properties: [{ name: 'width', getter: getCssPropertyByName, setters: [setCssProperty] }],
    ...getWidthFieldConfig(),
    options: {
      '%': {
        min: 0,
        max: 100,
        step: 1,
        defaultValue: 100,
        isDefaultUnitSelected: true,
      },
    },
  },
  {
    label: 'templateBuilder.fields.alignment',
    selector: '{SELECTOR}.spm_widget_separator > tbody > tr > th table.spm_separator',
    properties: [{ name: 'align', getter: getAttributeByName, setters: [setAttribute] }],
    ...getElementAlignFieldConfig('align', AlignValueEnum.CENTER),
  },
  {
    label: 'templateBuilder.fields.mediaDisplay',
    selector: '{SELECTOR} ',
    properties: [{ name: 'show-for-', getter: getClassByName, setters: [setMediaDisplay] }],
    ...mediaDisplayFieldConfig,
  },
];

export default widgetSeparatorFieldsConfig;
