
import {
  computed,
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  PropType,
  Ref,
  ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import Editor from 'primevue/editor';
import {
  ColorFormatEnum,
  FieldConfig,
  ParserFieldObject,
  Property,
} from '@/types';
import EmojisButton from '@/components/fields/partials/EmojisButton.vue';
import VariablesButton from '@/components/fields/partials/VariablesButton.vue';
import Quill from '@/components/template-builder/utils/quill-setup';
import CustomColorPicker from '@/components/fields/partials/CustomColorPicker.vue';
import OverlayPanel from 'primevue/overlaypanel';
import getLineHeightList from '@/configs/line-height';
import BaseInputNumber from '@/components/fields/partials/BaseInputNumber.vue';
import AIButton from '@/components/fields/AIButton.vue';

export default defineComponent({
  name: 'WysiwygEditor',
  components: {
    AIButton,
    BaseInputNumber,
    OverlayPanel,
    CustomColorPicker,
    VariablesButton,
    EmojisButton,
    Editor,
  },

  props: {
    configs: {
      type: Object as PropType<FieldConfig>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserFieldObject>,
      required: true,
    },
  },

  emits: {
    'on-change-properties': Object,
  },

  setup(props, context) {
    const { t } = useI18n();
    const properties: Ref<Array<Property>> = ref(props.parserValues.properties);
    const inputValue: Ref<string> = ref(properties.value[0].value ?? props.configs.options.defaultValue);
    const editorRef = ref();
    const editorKey = ref(0);
    const overlayPanelRef = ref();
    const overlayPanelBackgroundRef = ref();
    const overlayPanelLetterSpacingRef = ref();
    const colorFormat: Ref<string> = ref(ColorFormatEnum.HEX);
    const lineHeightList = ref([{}]);
    const letterSpacingValue = ref();
    const selectedRange = ref();

    const handleValueChange = () => {
      inputValue.value = editorRef.value.quill.root.innerHTML;
      const updatedProperties: Array<Property> = properties.value.map((prop: Property) => {
        const updatedProperty = prop;
        updatedProperty.value = inputValue.value;
        return updatedProperty;
      });
      context.emit('on-change-properties', {
        selector: props.parserValues.selector,
        properties: updatedProperties,
      });
    };

    const insertContent = (value: string | number | undefined) => {
      editorRef.value.quill.insertText(editorRef.value.quill.selection.savedRange.index, value);
      handleValueChange();
    };

    const replaceContent = (value: string) => {
      editorRef.value.quill.clipboard.dangerouslyPasteHTML(value);
      handleValueChange();
    };

    const toggleColorPicker = (event: MouseEvent) => {
      overlayPanelRef.value.toggle(event);
    };

    const toggleColorPickerBackground = (event: MouseEvent) => {
      overlayPanelBackgroundRef.value.toggle(event);
    };

    const changeColor = (color: string) => {
      editorRef.value.quill.format('color', color);
      handleValueChange();
    };

    const changeColorBackground = (color: string) => {
      editorRef.value.quill.format('background-color', color);
      handleValueChange();
    };
    const saveRangeSelection = (event: MouseEvent) => {
      // Store selected range (because when opening the panel, the range disappears)
      const range = editorRef.value.quill.getSelection();
      if (range !== null) {
        selectedRange.value = range;
      }
    };
    const toggleLetterSpacing = (event: MouseEvent) => {
      letterSpacingValue.value = null;
      overlayPanelLetterSpacingRef.value.toggle(event);
      editorRef.value.quill.setSelection(selectedRange.value);
    };

    const changeLetterSpacing = (value: any) => {
      if (selectedRange.value) {
        editorRef.value.quill.setSelection(selectedRange.value);
        editorRef.value.quill.formatText(selectedRange.value.index, selectedRange.value.length, {
          'letter-spacing': `${value}px`,
        });
      }

      handleValueChange();
    };

    onBeforeMount(async () => {
      try {
        lineHeightList.value = await getLineHeightList();
        lineHeightList.value.unshift({
          label: t('templateBuilder.fields.defaultLineHeight'),
          value: '',
        });
      } catch (error) {
        lineHeightList.value = [{}];
      }

      editorKey.value += 1;

      // Avoids having an empty history point
      const checkInterval = window.setInterval(() => {
        if (editorRef.value && editorRef.value.quill) {
          editorRef.value.quill.history.clear();
          window.clearInterval(checkInterval);
        }
      }, 200);
    });

    // Nettoyage avant démontage
    onBeforeUnmount(() => {
      if (editorRef.value?.quill) {
        editorRef.value.quill.off();
        editorRef.value.quill.disable();
        editorRef.value.quill = null;
      }
    });

    return {
      t,
      inputValue,
      editorRef,
      editorKey,
      overlayPanelRef,
      overlayPanelBackgroundRef,
      overlayPanelLetterSpacingRef,
      colorFormat,
      lineHeightList,
      letterSpacingValue,
      changeColor,
      toggleColorPicker,
      changeColorBackground,
      toggleColorPickerBackground,
      handleValueChange,
      insertContent,
      replaceContent,
      saveRangeSelection,
      toggleLetterSpacing,
      changeLetterSpacing,
    };
  },
});
