import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { getTemplateIframeDocument } from '@/components/template-builder/utils/helpers';
import { AI_IMAGE_MAX_HEIGHT, AI_IMAGE_MAX_WIDTH } from '@/components/template-builder/utils/constants';
import { reactive, readonly } from 'vue';
import { UserState, getShopDefaultLang } from '@/composables/User';
import { Translation } from '@/types';
// eslint-disable-next-line import/no-cycle
import {
  TemplateEditorState as templateEditorState,
} from '@/composables/template-editor/TemplateEditor';

import ISO6391 from 'iso-639-1';
import sha1 from 'sha1';

export async function MakeSPMAIRequest(
  route: string, data: object,
): Promise<any | null> {
  return axios.create({ baseURL: '/shopimind-ai/' })
    .post<any>(route, data)
    .catch((error: any) => {
      if (error.response) {
        return error;
      }
      return false;
    });
}

async function getTemplateTextForAI(
  idShop: number, idTemplate: number, lang: string,
): Promise<any | null> {
  return axios.create({ baseURL: '/shopimind-nest/' })
    .get<any>(`/template/template-for-ai/${idShop}/${idTemplate}/${lang}`)
    .catch((error: any) => {
      if (error.response) {
        return error;
      }
      return false;
    });
}

interface AIInterfaceParams {
  isAIModalVisible: boolean;
  AIContentType: number;
  AIContextContent: string;
  AIContextContentText: string;
  AIContextFullContentText: string;
  AIContextContentHTML: string;
  AIApplyContentParams: object;
}

interface State {
  AIInterfaceParams: AIInterfaceParams;
}

const state = reactive<State>({
  AIInterfaceParams: {
    isAIModalVisible: false, AIContentType: 0, AIContextContent: '', AIContextFullContentText: '', AIContextContentText: '', AIContextContentHTML: '', AIApplyContentParams: {},
  },
});

export const ShopimindAIState = readonly(state);

export function hideAIModal() {
  state.AIInterfaceParams.isAIModalVisible = false;
}

async function getAllTemplateContentForContext(idTemplate: number) {
  let returnContent = '';

  if (templateEditorState && templateEditorState.isTemplateEditorVisible && templateEditorState.template && templateEditorState.template.translations) {
    // Get from db
    const textEntries: string[] = [];
    const excludeSubstrings: string[] = ['template.view_in_browser_url', 'template.unsubscribe_url']; // Replace with the substrings you want to exclude

    const shouldExclude = (str: string): boolean => excludeSubstrings.some((substring) => str.includes(substring));
    // Assuming htmlToText is a function that converts HTML to plain text
    const htmlToText = (html: string): string =>
      // Example implementation of htmlToText (you should use a proper library)
      // eslint-disable-next-line implicit-arrow-linebreak
      html.replace(/<[^>]*>?/gm, '');
    // eslint-disable-next-line max-len
    const currentTranslations = templateEditorState.template.translations.filter((translation: any) => (translation.fieldType === 'text' || translation.fieldType === 'html' || translation.fieldType === 'textarea') && translation.language === getShopDefaultLang());

    currentTranslations.forEach((translation) => {
      if (translation.value && !shouldExclude(translation.value)) {
        const plainText = translation.fieldType === 'html' ? htmlToText(translation.value) : translation.value;
        const cleanedText = plainText.replace(/\n+/g, '\n').trim(); // Replace multiple newlines with a single one
        textEntries.push(cleanedText);
      }
    });
    returnContent = textEntries.join('\n');
  } else {
    // Get from db
    const templateTextForAI = await getTemplateTextForAI((UserState.activeShop ? UserState.activeShop.id : 0), idTemplate, getShopDefaultLang());
    if (templateTextForAI && templateTextForAI.data && templateTextForAI.data.success && templateTextForAI.data.data) {
      returnContent = templateTextForAI.data.data;
    } else {
      returnContent = '';
    }
  }
  return returnContent;
}

export async function showAIModal(event: Event,
  AIContentType: number,
  AIApplyContentParams: { selector: string; type: string; target: string; attr: string; imageWidth: number; imageHeight: number; idTemplate: number}) {
  let AIContextContent: string | null | undefined;
  let currentDocument: Document;

  if (templateEditorState && templateEditorState.isTemplateEditorVisible) {
    const template = getTemplateIframeDocument();
    currentDocument = (AIApplyContentParams.target === 'template' ? template : document);
  } else {
    currentDocument = document;
  }
  const contextElement = currentDocument?.querySelector(AIApplyContentParams.selector);
  let AIContextContentText: string | null | undefined;
  let AIContextContentHTML: string | null | undefined;
  let AIContextFullContentText: string | null | undefined;
  switch (parseInt(String(AIContentType), 10)) {
    // text
    case 1:
      AIContextFullContentText = await getAllTemplateContentForContext(AIApplyContentParams.idTemplate);
      AIContextContent = contextElement ? contextElement.textContent : null;
      if (contextElement && contextElement.querySelector('spmtranslation')) {
        AIContextContentHTML = contextElement?.querySelector('spmtranslation')?.innerHTML;
        AIContextContentText = contextElement?.querySelector('spmtranslation')?.textContent;
      } else {
        AIContextContentHTML = contextElement ? contextElement.innerHTML : null;
        AIContextContentText = contextElement ? contextElement.textContent : null;
      }
      break;
    // subject
    case 2:

      AIContextFullContentText = await getAllTemplateContentForContext(AIApplyContentParams.idTemplate);

      if (AIContextFullContentText) {
        AIContextContent = contextElement ? `${(contextElement as HTMLInputElement).value}\n${AIContextFullContentText}` : AIContextFullContentText;
        AIContextFullContentText = AIContextContent;
      } else {
        AIContextContent = contextElement ? (contextElement as HTMLInputElement).value : null;
        AIContextFullContentText = AIContextContent;
      }
      AIContextContentText = (contextElement as HTMLInputElement).value;
      break;
    // Image
    case 3:
      AIContextFullContentText = await getAllTemplateContentForContext(AIApplyContentParams.idTemplate);
      if (AIApplyContentParams.selector && currentDocument?.querySelector(AIApplyContentParams.selector)) {
        AIContextContent = currentDocument?.querySelector(AIApplyContentParams.selector)?.getAttribute('src');
        if (contextElement) {
          const currentImage = contextElement as HTMLImageElement;
          const imageWidth = currentImage.naturalWidth;
          const imageHeight = currentImage.naturalHeight;
          // Mettre à jour les paramètres avec les nouvelles dimensions
          // eslint-disable-next-line no-param-reassign
          AIApplyContentParams.imageWidth = imageWidth;
          // eslint-disable-next-line no-param-reassign
          AIApplyContentParams.imageHeight = imageHeight;
        }
      }
      break;
    default:
      break;
  }
  state.AIInterfaceParams.isAIModalVisible = true;
  state.AIInterfaceParams.AIContentType = AIContentType;
  state.AIInterfaceParams.AIApplyContentParams = AIApplyContentParams;

  if (AIContextContent) {
    state.AIInterfaceParams.AIContextContent = AIContextContent;
  }
  if (AIContextContentText) {
    state.AIInterfaceParams.AIContextContentText = AIContextContentText;
  }
  if (AIContextFullContentText) {
    state.AIInterfaceParams.AIContextFullContentText = AIContextFullContentText;
  }
  if (AIContextContentHTML) {
    state.AIInterfaceParams.AIContextContentHTML = AIContextContentHTML;
  }
}

export function GetReadableShopDefaultLang(): string {
  try {
    const defaultLangCode = getShopDefaultLang();
    if (!defaultLangCode) {
      return '';
    }

    const languageName = ISO6391.getName(defaultLangCode);
    return languageName || '';
  } catch (error) {
    console.error('Error fetching the shop default language:', error);
    return '';
  }
}

export async function getStyleParameter(idShop: number, styleParameter: string) {
  return axios.create({ baseURL: '/shopimind-ai/' })
    .get<any>(`configuration/get-${styleParameter}/${idShop}/${sha1(`${process.env.VUE_APP_SPMAI_API_SALT}${idShop}`)}`)
    .catch((error: any) => {
      if (error.response) {
        return error;
      }
      return false;
    });
}

export async function setStyleParameter(idShop: number, styleParameter: string, data: any) {
  return axios.create({ baseURL: '/shopimind-ai/' })
    .post<any>(`configuration/set-${styleParameter}/${idShop}/${sha1(`${process.env.VUE_APP_SPMAI_API_SALT}${idShop}`)}`, data)
    .catch((error: any) => {
      if (error.response) {
        return error;
      }
      return false;
    });
}

export async function isStyleConfigured(idShop: number) {
  try {
    const styleContent = await getStyleParameter(idShop, 'editorial-style-content');
    const businessDescription = await getStyleParameter(idShop, 'business-description');

    return styleContent
      && styleContent.data
      && styleContent.data.result
      && businessDescription
      && businessDescription.data
      && businessDescription.data.result;
  } catch (err) {
    return false;
  }
}
