export const faPlusRegular = `
<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" data-prefix="far" data-icon="plus"
  class="svg-inline--fa fa-plus" role="img" viewBox="0 0 384 512" style="width: 15px; height:auto; padding: 8px;box-sizing: content-box;pointer-events: auto;"
>
  <path fill="currentColor" d="M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16
   16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16
   16-16v-32c0-8.84-7.16-16-16-16z">
  </path>
</svg>`;

export const faSaveRegular = `
<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" data-prefix="far" data-icon="save"
  class="svg-inline--fa fa-save" role="img" viewBox="0 0 448 512" style="width: 15px;height:auto; padding: 8px;box-sizing: content-box;pointer-events: auto;"
>
  <path fill="#5C5F62" d="M433.941 129.941l-83.882-83.882A48 48 0 0 0 316.118 32H48C21.49 32 0 53.49 0 80v352c0
   26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V163.882a48 48 0 0 0-14.059-33.941zM272 80v80H144V80h128zm122 352H54a6
   6 0 0 1-6-6V86a6 6 0 0 1 6-6h42v104c0 13.255 10.745 24 24 24h176c13.255 0 24-10.745 24-24V83.882l78.243 78.243a6
   6 0 0 1 1.757 4.243V426a6 6 0 0 1-6 6zM224 232c-48.523 0-88 39.477-88 88s39.477 88 88 88 88-39.477
   88-88-39.477-88-88-88zm0 128c-22.056 0-40-17.944-40-40s17.944-40 40-40 40 17.944 40 40-17.944 40-40 40z">
  </path>
</svg>`;

export const faCloneRegular = `
<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" data-prefix="far" data-icon="clone"
  class="svg-inline--fa fa-clone" role="img" viewBox="0 0 512 512" style="width: 15px;height:auto; padding: 10px;box-sizing: content-box;pointer-events: auto;"
>
  <path fill="#5C5F62" d="M464 0H144c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49
   48 48 48h320c26.51 0 48-21.49 48-48v-48h48c26.51 0 48-21.49 48-48V48c0-26.51-21.49-48-48-48zM362 464H54a6 6 0 0
   1-6-6V150a6 6 0 0 1 6-6h42v224c0 26.51 21.49 48 48 48h224v42a6 6 0 0 1-6 6zm96-96H150a6 6 0 0 1-6-6V54a6 6 0 0 1
   6-6h308a6 6 0 0 1 6 6v308a6 6 0 0 1-6 6z">
  </path>
</svg>`;

export const faTrashAltRegular = `
<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" data-prefix="far" data-icon="trash-alt"
  class="button-delete svg-inline--fa fa-trash-alt" role="img" viewBox="0 0 448 512" style="width: 15px; height:auto; padding: 9px;box-sizing: content-box;pointer-events: auto;"
>
  <path fill="#5C5F62" d="M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0
   0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0
   96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0
   0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12
   12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z">
  </path>
</svg>`;
export const faArrowToBottom = `
<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" data-prefix="far" data-icon="trash-alt"
class="widget-placeholder svg-inline--fa fa-arrow-to-bottom" role="img" viewBox="0 0 384 512"
style="width: 15px;height:auto; padding: 9px;box-sizing: content-box;pointer-events: auto;"
>
    <path fill="#5C5F62" d="M348.5 232.1l-148 148.4c-4.7 4.7-12.3 4.7-17 0l-148-148.4c-4.7-4.7-4.7-12.3 0-17l19.6-19.6c4.8-4.8 12.5-4.7
      17.1.2l93.7 97.1V44c0-6.6 5.4-12 12-12h28c6.6 0 12 5.4 12 12v248.8l93.7-97.1c4.7-4.8 12.4-4.9 17.1-.2l19.6 19.6c4.9 4.7
      4.9 12.3.2 17zM372 428H12c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h360c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12z">
    </path>
</svg>`;

// eslint-disable-next-line max-len
export const faAiAltRegular = '<svg xmlns="http://www.w3.org/2000/svg"  aria-hidden="true" data-prefix="far" data-icon="ai-alt"  class="widget-placeholder svg-inline--fa fa-microchip-ai" role="img" viewBox="0 0 512 512" style="width: 22px;height:auto; padding: 6px;box-sizing: content-box;pointer-events: auto;"><path fill="#009978" d="M160 0c8.8 0 16 7.2 16 16V64h64V16c0-8.8 7.2-16 16-16s16 7.2 16 16V64h64V16c0-8.8 7.2-16 16-16s16 7.2 16 16V64h16c35.3 0 64 28.7 64 64v16h48c8.8 0 16 7.2 16 16s-7.2 16-16 16H448v64h48c8.8 0 16 7.2 16 16s-7.2 16-16 16H448v64h48c8.8 0 16 7.2 16 16s-7.2 16-16 16H448v16c0 35.3-28.7 64-64 64H368v48c0 8.8-7.2 16-16 16s-16-7.2-16-16V448H272v48c0 8.8-7.2 16-16 16s-16-7.2-16-16V448H176v48c0 8.8-7.2 16-16 16s-16-7.2-16-16V448H128c-35.3 0-64-28.7-64-64V368H16c-8.8 0-16-7.2-16-16s7.2-16 16-16H64V272H16c-8.8 0-16-7.2-16-16s7.2-16 16-16H64V176H16c-8.8 0-16-7.2-16-16s7.2-16 16-16H64V128c0-35.3 28.7-64 64-64h16V16c0-8.8 7.2-16 16-16zM128 96c-17.7 0-32 14.3-32 32V384c0 17.7 14.3 32 32 32H384c17.7 0 32-14.3 32-32V128c0-17.7-14.3-32-32-32H128zm94.7 89.6l56 128c3.5 8.1-.1 17.5-8.2 21.1s-17.5-.2-21.1-8.2L239.5 304H176.5l-9.8 22.4c-3.5 8.1-13 11.8-21.1 8.2s-11.8-13-8.2-21.1l56-128c2.5-5.8 8.3-9.6 14.7-9.6s12.1 3.8 14.7 9.6zM208 231.9L190.5 272h35.1L208 231.9zM352 192V320c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16z"/></svg>';

// eslint-disable-next-line max-len
export const faColumn = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 256 256" enable-background="new 0 0 256 256" xml:space="preserve" class="widget-placeholder" style="width: 21px;height:auto; padding: 7px;box-sizing: content-box;pointer-events: auto;"><g><g><path fill="#5C5F62" data-title="Layer 0" xs="0" d="M39.5,39.5h177c8.1,0,15.1,2.9,20.9,8.6c5.8,5.8,8.6,12.7,8.6,20.9v118c0,8.1-2.9,15.1-8.6,20.9c-5.8,5.8-12.7,8.6-20.9,8.6h-177c-8.1,0-15.1-2.9-20.9-8.6c-5.8-5.8-8.6-12.7-8.6-20.9V69c0-8.1,2.9-15.1,8.6-20.9C24.4,42.4,31.4,39.5,39.5,39.5z M78.8,196.8V59.2H39.5c-2.7,0-5,1-7,2.9c-1.9,1.9-2.9,4.2-2.9,7v118c0,2.7,1,5,2.9,7c1.9,1.9,4.2,2.9,7,2.9L78.8,196.8L78.8,196.8z M157.5,196.8V59.2h-59v137.7L157.5,196.8L157.5,196.8z M216.5,59.2h-39.3v137.7h39.3c2.7,0,5-1,7-2.9c1.9-1.9,2.9-4.2,2.9-7V69c0-2.7-1-5-2.9-7C221.5,60.1,219.2,59.2,216.5,59.2L216.5,59.2z"/></g></g></svg>';
