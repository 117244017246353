
// eslint-disable-next-line max-classes-per-file
import {
  defineComponent, onBeforeMount,
  onMounted,
  onBeforeUnmount,
  onUpdated,
  PropType,
  Ref,
  ref,
  SetupContext,
} from 'vue';

import { useI18n } from 'vue-i18n';

import Editor from 'primevue/editor';
import EmojisButton from '@/components/fields/partials/EmojisButton.vue';
import VariablesButton from '@/components/fields/partials/VariablesButton.vue';
import Quill, { registerImportTemplateSpecialsRegistering } from '@/components/template-builder/utils/quill-setup';
import CustomColorPicker from '@/components/fields/partials/CustomColorPicker.vue';
import OverlayPanel from 'primevue/overlaypanel';

import { ColorFormatEnum, Translation } from '@/types';

// eslint-disable-next-line import/no-cycle
import {
  getTranslationLabel,
} from '@/components/template-builder/utils/helpers';

import { TemplateEditorState as state } from '@/composables/template-editor/TemplateEditor';
import AIButton from '@/components/fields/AIButton.vue';
import BaseInputNumber from '@/components/fields/partials/BaseInputNumber.vue';
import getLineHeightList from '@/configs/line-height';
import { Variable } from '../template-builder/utils/variables-list';

export default defineComponent({
  name: 'BaseWysiwygEditor',
  components: {
    BaseInputNumber,
    AIButton,
    OverlayPanel,
    CustomColorPicker,
    VariablesButton,
    EmojisButton,
    Editor,
  },

  props: {
    modelValue: {
      type: String,
      required: true,
    },

    variablesList: {
      type: Array as PropType<Variable[]>,
      required: false,
      default: () => [],
    },

    translation: {
      type: Object as PropType<Translation>,
      required: false,
      default: null,
    },

  },

  emits: {
    'update:modelValue': String,
    'activate-group': String,
    'deactivate-group': String,
  },

  setup(props, { emit }: SetupContext) {
    const { t } = useI18n();

    const overlayPanelLetterSpacingRef = ref();
    const lineHeightList = ref([{}]);
    const letterSpacingValue = ref();
    const selectedRange = ref();
    const editorKey = ref(0);
    const inputValue: Ref<string> = ref('');
    const editorRef = ref();
    const overlayPanelRef = ref();
    const overlayPanelBackgroundRef = ref();
    const colorFormat: Ref<string> = ref(ColorFormatEnum.HEX);

    const handleValueChange = () => {
      inputValue.value = editorRef.value.quill.root.innerHTML;
      emit('update:modelValue', inputValue.value);
    };

    const insertContent = (value: string | number | undefined) => {
      if (editorRef.value && editorRef.value.quill) {
        editorRef.value.quill.insertText(editorRef.value.quill.selection.savedRange.index, value);
        handleValueChange();
      }
    };

    const toggleColorPicker = (event: MouseEvent) => {
      overlayPanelRef.value.toggle(event);
    };

    const toggleColorPickerBackground = (event: MouseEvent) => {
      overlayPanelBackgroundRef.value.toggle(event);
    };

    const changeColor = (color: string) => {
      editorRef.value.quill.format('color', color);
      handleValueChange();
    };

    const changeColorBackground = (color: string) => {
      editorRef.value.quill.format('background-color', color);
      handleValueChange();
    };

    const saveRangeSelection = (event: MouseEvent) => {
      // Store selected range (because when opening the panel, the range disappears)
      const range = editorRef.value.quill.getSelection();
      if (range !== null) {
        selectedRange.value = range;
      }
    };
    const toggleLetterSpacing = (event: MouseEvent) => {
      letterSpacingValue.value = null;
      overlayPanelLetterSpacingRef.value.toggle(event);
      editorRef.value.quill.setSelection(selectedRange.value);
    };

    const changeLetterSpacing = (value: any) => {
      if (selectedRange.value) {
        editorRef.value.quill.setSelection(selectedRange.value);
        editorRef.value.quill.formatText(selectedRange.value.index, selectedRange.value.length, {
          'letter-spacing': `${value}px`,
        });
        handleValueChange();
      }
    };

    onBeforeMount(async () => {
      try {
        lineHeightList.value = await getLineHeightList();
        lineHeightList.value.unshift({
          label: t('templateBuilder.fields.defaultLineHeight'),
          value: '',
        });
      } catch (error) {
        lineHeightList.value = [{}];
      }
      editorKey.value += 1;
    });

    onMounted(async () => {
      if (state.template?.informations.imported) {
        registerImportTemplateSpecialsRegistering();
      }
      if (editorRef.value && editorRef.value.quill) {
        editorRef.value.quill.disable();
      }

      inputValue.value = props.modelValue;

      // Avoids having an empty history point
      const checkInterval = window.setInterval(() => {
        if (editorRef.value && editorRef.value.quill) {
          editorRef.value.quill.history.clear();
          window.clearInterval(checkInterval);
        }
      }, 200);
    });

    onUpdated(() => {
      if (editorRef.value && editorRef.value.quill) {
        editorRef.value.quill.enable();
      }
    });

    // Nettoyage avant démontage
    onBeforeUnmount(() => {
      if (editorRef.value?.quill) {
        editorRef.value.quill.off();
        editorRef.value.quill.disable();
        editorRef.value.quill = null;
      }
    });


    const Delta = Quill.import('delta');
    const lineBreakMatcher = () => {
      const newDelta = new Delta();
      newDelta.insert({ break: '' });
      return newDelta;
    };

    // Update Quill behavior when using 'enter' key
    const handleEnter = (range: any, context: any) => {
      const nextCharacter = editorRef.value.quill.getText(range.index + 1, 1);
      editorRef.value.quill.insertEmbed(range.index, 'break', true, 'user');
      if (nextCharacter.length === 0) {
        editorRef.value.quill.insertEmbed(range.index, 'break', true, 'user');
      }
      editorRef.value.quill.setSelection(range.index + 1, Quill.sources.SILENT);
    };

    const quillModules = ref({
      clipboard: {
        matchVisual: false,
        matchers: [
          ['BR', lineBreakMatcher], // Quill use clipboard when initializing the first value
        ],
      },
      keyboard: {
        bindings: {
          handleEnter: {
            key: 13,
            handler: handleEnter,
          },
          linebreak: {
            key: 13,
            shiftKey: true,
            handler: handleEnter,
          },
        },
      },
    });

    const focus = () => {
      if (editorRef.value && editorRef.value.quill) {
        editorRef.value.quill.blur();
        editorRef.value.quill.focus();
      }
    };

    const handleEditorLoaded = () => {
      editorRef.value.quill.root.addEventListener('blur', () => {
        if (props.translation) {
          emit('deactivate-group', props.translation.groupKey);
        }
      });
      editorRef.value.quill.root.addEventListener('focus', () => {
        if (props.translation) {
          emit('activate-group', props.translation.groupKey);
        }
      });
    };

    return {
      t,
      inputValue,
      editorRef,
      overlayPanelRef,
      overlayPanelBackgroundRef,
      handleValueChange,
      insertContent,
      toggleColorPicker,
      toggleColorPickerBackground,
      changeColor,
      changeColorBackground,
      colorFormat,
      quillModules,
      focus,
      getTranslationLabel,
      handleEditorLoaded,
      lineHeightList,
      letterSpacingValue,
      toggleLetterSpacing,
      saveRangeSelection,
      changeLetterSpacing,
      overlayPanelLetterSpacingRef,
      editorKey,
    };
  },
});
