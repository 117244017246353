// quill-setup.ts

// eslint-disable-next-line max-classes-per-file
import Quill from 'quill';

/* --------------------------------------------------
   1) Importer tout ce dont on a besoin pour Quill
-----------------------------------------------------*/
const DirectionAttribute = Quill.import('attributors/attribute/direction');
const AlignStyle = Quill.import('attributors/style/align');
const BackgroundStyle = Quill.import('attributors/style/background');
const ColorStyle = Quill.import('attributors/style/color');
const DirectionStyle = Quill.import('attributors/style/direction');
const FontStyle = Quill.import('attributors/style/font');
const SizeStyle = Quill.import('attributors/style/size');
const Bold = Quill.import('formats/bold');
const Parchment = Quill.import('parchment');
const Break = Quill.import('blots/break');
const Block = Quill.import('blots/block');
const Inline = Quill.import('blots/inline');
const Icons = Quill.import('ui/icons');
Icons.align.left = Icons.align['']; // set icon for 'left' option, otherwise it's replaced with 'undefined' text
/* --------------------------------------------------
   2) Personnaliser si besoin (whitelist, tagName, etc.)
-----------------------------------------------------*/

// Ex : autoriser align: left + version custom pour la toolbar
AlignStyle.whitelist = ['left', 'center', 'right', 'justify'];

// Forcer l'utilisation de <b> à la place de <strong>
Bold.tagName = 'B';

// Autoriser certaines polices
FontStyle.whitelist = [
  '',
  'arial, helvetica, sans-serif',
  'comic sans ms, cursive',
  'courier new, courier, monospace',
  'georgia, serif',
  'lucida sans unicode, lucida grande, sans-serif',
  'tahoma, geneva, sans-serif',
  'times new roman, times, serif',
  'trebuchet ms, helvetica, sans-serif',
  'verdana, geneva, sans-serif',
];

// Autoriser certaines tailles
SizeStyle.whitelist = [
  '',
  '8px', '9px', '10px', '11px', '12px',
  '13px', '14px', '15px', '16px', '18px',
  '20px', '22px', '24px', '26px', '28px',
  '30px', '32px', '34px', '36px', '38px',
  '40px',
];

/* --------------------------------------------------
   3) Configurer line-height et letter-spacing
-----------------------------------------------------*/
const lineHeightConfig = {
  scope: Parchment.Scope.INLINE,
  whitelist: null, // tu peux mettre un tableau si besoin
};

const lineHeightAttributor = new Parchment.Attributor.Style('lineheight', 'line-height', lineHeightConfig);

const letterSpacingAttributor = new Parchment.Attributor.Style('letterspacing', 'letter-spacing', {
  scope: Parchment.Scope.INLINE,
});

/* --------------------------------------------------
   4) Redéfinir/étendre certains blots de Quill
-----------------------------------------------------*/

// A) Break custom (pour éviter la suppression de <br>)
class FixedBreak extends Break {
  insertInto(parent: any, ref: any) {
    Parchment.Embed.prototype.insertInto.call(this, parent, ref);
  }

  // eslint-disable-next-line class-methods-use-this
  length() {
    return 1;
  }

  // eslint-disable-next-line class-methods-use-this
  value() {
    return '\n';
  }
}

// B) Block custom (conserve les attributs sur <p> par ex.)
class FixedBlock extends Block {
  static create(value: any) {
    const node = super.create(value);
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in value) {
      node.setAttribute(key, value[key]);
    }
    return node;
  }

  static formats(domNode: any) {
    const attrs: Record<string, any> = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const attr of domNode.attributes) {
      attrs[attr.name] = attr.value;
    }
    return attrs;
  }
}
FixedBlock.allowedChildren.push(FixedBreak);
FixedBlock.blotName = 'fixed_block';

/* --------------------------------------------------
   5) Blot inline custom (mais NON enregistré par défaut)
      On le définit seulement, pour l'activer conditionnellement
-----------------------------------------------------*/
class FixedInline extends Inline {
  static create(value: any) {
    const node = super.create(value);
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in value) {
      node.setAttribute(key, value[key]);
    }
    return node;
  }

  static formats(domNode: any) {
    const attrs: Record<string, any> = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const attr of domNode.attributes) {
      attrs[attr.name] = attr.value;
    }
    return attrs;
  }
}
FixedInline.blotName = 'fixed_inline';

/* --------------------------------------------------
   6) Enregistrer globalement tout ce qui DOIT l'être
      (sauf le FixedInline si on veut l'activer conditionnellement)
-----------------------------------------------------*/
Quill.register(DirectionAttribute, true);
Quill.register(AlignStyle, true);
Quill.register(BackgroundStyle, true);
Quill.register(ColorStyle, true);
Quill.register(DirectionStyle, true);
Quill.register(FontStyle, true);
Quill.register(SizeStyle, true);
Quill.register(Bold, true);
Quill.register(lineHeightAttributor, true);
Quill.register(letterSpacingAttributor, true);

/* --------------------------------------------------
   7) Exporter une fonction d'enregistrement conditionnel
      pour FixedInline
-----------------------------------------------------*/
let isImportTemplateSpecialsRegistered = false;
export function registerImportTemplateSpecialsRegistering() {
  if (!isImportTemplateSpecialsRegistered) {
    Quill.register(FixedInline, true);
    Quill.register(FixedBreak, true);
    Quill.register(FixedBlock, true);
    isImportTemplateSpecialsRegistered = true;
  }
}

/* --------------------------------------------------
   8) Exporter Quill configuré par défaut
-----------------------------------------------------*/
export default Quill;
