import {
  WidgetTypeConfig,
  ConfigurationPanelStructureItemEnum,
  LinkTypeEnum,
  HeadingValueEnum,
  ComponentNameEnum,
  TextAlignValueEnum,
} from '@/types';
import getHorizontalSpacesFieldConfig from '@/components/template-builder/config/fields/horizontal-spaces-config';
import getVerticalSpacesFieldConfig from '@/components/template-builder/config/fields/vertical-spaces-config';
// eslint-disable-next-line import/no-cycle
import {
  setLink,
  setTagType,
  setContent,
  setCssProperty,
  setMediaDisplay,
  setCssPropertyAndRemoveIfZero, recalculateLineHeightValue,
} from '@/components/template-builder/setters';
// eslint-disable-next-line import/no-cycle
import {
  getClassByName,
  getContent, getContentWithLineBreaks,
  getCssPropertyByName,
  getLinkType,
  getTagType,
} from '@/components/template-builder/getters';
import variables from '@/components/template-builder/utils/variables-list';
import fontFieldConfig from '@/components/template-builder/config/fields/font-config';
import getTextAlignFieldConfig from '@/components/template-builder/config/fields/text-align-config';
import getTextareaVariablesFieldConfig from '@/components/template-builder/config/fields/textarea-variables-config';
import getColorFieldConfig from '../fields/color-config';
import bordersFieldConfig from '../fields/borders-config';
import mediaDisplayFieldConfig from '../fields/media-display-config';
import getInputVariablesFieldConfig from '../fields/input-variables-config';
import getLinkFieldConfig from '../fields/link-config';
import getDropdownFieldConfig from '../fields/dropdown-config';

const linkOptions = [LinkTypeEnum.NONE, LinkTypeEnum.WEB_ADDRESS, LinkTypeEnum.EMAIL_ADDRESS, LinkTypeEnum.ANCHOR];
// if (isDisplayTemplate(templateType)) linkOptions.push(LinkTypeEnum.DISPLAY_TEMPLATE);

const widgetTitleConfig: WidgetTypeConfig = {
  tabPanel: {
    label: 'templateBuilder.widgetsHeaders.title',
    items: [
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.widgetConfiguration',
        selector: 'body #spm_body #{ID}',
        items: [
          {
            label: 'templateBuilder.fields.heading.label',
            selector: '{SELECTOR} h1, {SELECTOR} h2, {SELECTOR} h3, {SELECTOR} h4',
            displayToggle: false,
            properties: [{ name: 'content', getter: getTagType, setters: [setTagType] }],
            ...getDropdownFieldConfig(Object.values(HeadingValueEnum)
              .map((heading) => ({ value: heading, label: `templateBuilder.fields.heading.${heading}` })), HeadingValueEnum.H1),
          },
          {
            label: 'templateBuilder.fields.titleText',
            selector: '{SELECTOR} h1, {SELECTOR} h2, {SELECTOR} h3, {SELECTOR} h4',
            properties: [{ name: 'content', getter: getContentWithLineBreaks, setters: [setContent] }],
            ...getTextareaVariablesFieldConfig('Texte du bouton', variables.allVariables, true),
          },
          {
            label: 'templateBuilder.fields.link',
            selector: '{SELECTOR} > tbody > tr > th > table > tbody > tr > th, {SELECTOR}.spm_widget_display_title',
            properties: [{ name: 'href', getter: getLinkType, setters: [setLink] }],
            ...getLinkFieldConfig(LinkTypeEnum.NONE, linkOptions, variables.allVariables),
          },
        ],
      },
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.widgetStyles',
        selector: 'body #spm_body #{ID}',
        items: [
          {
            label: 'templateBuilder.fields.title',
            selector: '{SELECTOR} h1, {SELECTOR} h2, {SELECTOR} h3, {SELECTOR} h4',
            properties: [
              { name: 'font-family', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'font-size', getter: getCssPropertyByName, setters: [recalculateLineHeightValue, setCssProperty] },
              { name: 'font-weight', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'font-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'text-decoration-line', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'text-transform', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'color', getter: getCssPropertyByName, setters: [setCssProperty] },
            ],
            ...fontFieldConfig,
          },
          {
            label: 'templateBuilder.fields.titleAlign',
            selector: '{SELECTOR} h1, {SELECTOR} h2, {SELECTOR} h3, {SELECTOR} h4',
            properties: [{
              name: 'text-align',
              getter: getCssPropertyByName,
              setters: [setCssProperty],
            }],
            ...getTextAlignFieldConfig(TextAlignValueEnum.LEFT),
          },
          {
            label: 'templateBuilder.fields.backgroundColor',
            selector: '{SELECTOR} h1, {SELECTOR} h2, {SELECTOR} h3, {SELECTOR} h4',
            properties: [{
              name: 'background',
              getter: getCssPropertyByName,
              setters: [setCssProperty],
            }],
            ...getColorFieldConfig(undefined, false, true),
          },
          {
            label: 'templateBuilder.fields.internalMargins',
            selector: '{SELECTOR} h1, {SELECTOR} h2, {SELECTOR} h3, {SELECTOR} h4',
            properties: [
              { name: 'margin-top', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'margin-bottom', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'margin-left', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'margin-right', getter: getCssPropertyByName, setters: [setCssProperty] },
            ],
            componentName: ComponentNameEnum.SPACES,
            options: {
              ...getVerticalSpacesFieldConfig('margin').options,
              ...getHorizontalSpacesFieldConfig('margin').options,
            },
          },
          {
            label: 'templateBuilder.fields.borders',
            selector: '{SELECTOR} h1, {SELECTOR} h2, {SELECTOR} h3, {SELECTOR} h4',
            properties: [
              { name: 'border-color', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-style', getter: getCssPropertyByName, setters: [] },
              { name: 'border-width', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'border-bottom-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-bottom-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-bottom-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-top-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-top-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-top-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-left-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-left-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-left-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-right-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-right-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-right-width', getter: getCssPropertyByName, setters: [] },
            ],
            ...bordersFieldConfig,
          },
          {
            label: 'templateBuilder.fields.mediaDisplay',
            selector: '{SELECTOR}',
            properties: [{ name: 'show-for-', getter: getClassByName, setters: [setMediaDisplay] }],
            ...mediaDisplayFieldConfig,
          },
        ],
      },
    ],
  },
};

export default widgetTitleConfig;
